import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal, FormParser } from "../Components";
import { useNavigate, useParams } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import { MdOutlineFileCopy, MdOutlineVerified, MdVerified } from "react-icons/md"
import DataTable from 'react-data-table-component';
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import "./master_categories.css";
import { Modal } from 'react-bootstrap';
import Masters from "../Components/masters.json"
import { MdFilterList, MdFilterListOff, MdOutlineSearch } from "react-icons/md";
import { AddUser } from "./AddUser"
const Users = () => {
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [loading, setLoading] = useState(false)
    const [loginShow, setloginShow] = useState(false);
    const [GetAllUsers, setGetAllUsers] = useState("1");
    const [ShowFilters, setShowFilters] = useState(false);
    const handleLoginClose = () => setloginShow(false);
    const handleLoginShow = () => setloginShow(true);
    const [searchValue, setSearchValue] = useState('');
    const [RowId, SetRowId] = useState()
    let navigate = useNavigate();
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [popupType, setpopupType] = useState("")
    const [Profile_pic, setProfile_pic] = useState(false);
    const handleInputChange = (event) => {
        setSearchValue(event.target.value);
    };

    const handleSearchClick = () => {
        GetData();
    };
    function handleLoginEvent() {
        handleLoginClose();
    }
    function calculateAge(dob) {
        const dobDate = new Date(dob);
        const today = new Date();
        let age = today.getFullYear() - dobDate.getFullYear();
        const monthDiff = today.getMonth() - dobDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
            age--;
        }
        return age;
    };
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "center"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "center",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    }
    const [search, SetSearch] = useState({
        search: ""
    })
    const [parmsData, setparmsData] = useState("")
    const [Data, SetData] = useState([])
    const formSubmit = (Data) => {
        setparmsData(Data);
        setCurrentPage(1);
        console.log(Data);
        setShowFilters(false);
    }
    const GetData = async () => {
        console.log(GetAllUsers, "sdsdjxk", Profile_pic)
        setLoading(true);
        // try {
        //     let url = `/accounts/user-profiles/?page=${currentPage}&page_size=${RowPerPage}&get_all_users=${GetAllUsers}&search_query=${searchValue}&no_picture=${Profile_pic === true ? Profile_pic : ""}&is_pro=""`;
        //     const response = await axiosConfig.get(url);
        //     SetData(response.data.results);
        //     setLoading(false);
        //     setTotalRows(response.data.count);
        // } catch (error) {
        //     console.log(error);
        // }
        try {
            let params = {
                page: currentPage,
                page_size: RowPerPage,
                get_all_users: GetAllUsers,
                search_query: searchValue,
                no_picture: Profile_pic ? Profile_pic : "",
                ...parmsData
            };
            Object.keys(params).forEach(key => {
                if (params[key] === "" || params[key] === undefined) delete params[key];
            });
            const response = await axiosConfig.get(`/accounts/user-profiles/`, { params });
            SetData(response.data.results);
            setLoading(false);
            setTotalRows(response.data.count);
        } catch (error) {
            console.log(error);
        }
    };
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
        axiosConfig.get(`/accounts/user-profiles/search/?search_query=${value}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
    }
    const handleSort = (column, direction) => {
        console.log(column.selector, direction)
        axiosConfig.get(`/accounts/user-profiles/?page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.selector : -column.selector}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };
    const copyToClipboard = async (copy_item, item) => {
        console.log(item)
        let baseUrl = `${window.location.protocol}//${window.location.host}`;
        if (copy_item == "profile") {
            try {
                await navigator.clipboard.writeText(`${item.full_name} - ${item.designation} - ${calculateAge(item.dob)} years - ${item.state_city.city}(${item.state_city.state})  https://api.hyderabadcastingclub.com/share/profile/${item.hcc_id}/`);
                console.log('Text copied to clipboard!');
            } catch (err) {
                console.error('Failed to copy text to clipboard:', err);
            }
        } else {
            try {
                console.log(item)
                await navigator.clipboard.writeText(`${item.full_name} - ${item.designation} - ${calculateAge(item.dob)} years - ${item.state_city.city}(${item.state_city.state})  https://api.hyderabadcastingclub.com/share/profile/${item.hcc_id}/`);
                console.log('Text copied to clipboard!');
            } catch (err) {
                console.error('Failed to copy text to clipboard:', err);
            }
        }
    };
    useEffect(() => {
        GetData();
    }, [currentPage, RowPerPage, GetAllUsers, Profile_pic, parmsData]);
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true,
            width: "65px"

        },
        {
            name: "Name",
            selector: "full_name",
            cell: row =>
                <p onClick={() => window.open(`/dashboard/users/${row.id}/edit`, '_blank')}>
                    <b style={{ color: row.is_pro === true ? "#6A0DAD" : "" }}>{row.full_name}</b>   {row.is_celebrity ? <MdVerified size={12} color="#019bf9" /> : row.is_verified && <MdOutlineVerified size={12} color="#808080" />}
                </p>
            ,
            sortable: true,
            width: "200px"
        },
        {
            name: "Gender",
            selector: "gender",
            cell: row => <>{row.gender}({calculateAge(row.dob)} yrs)<br />{row.state_city && (<>{row.state_city.city}<br />({row.state_city.state})</>)}</>,
            sortable: true,
            width: "200px"
        },
        {
            name: "Contact Details",
            selector: "email",
            cell: row => <div>
                <b>{row.hcc_id}</b><br /> {row.mobile_number} <br /> {row.user.email}
            </div>,
            sortable: true
        },
        {
            name: "Profile",
            selector: "profile_pic",
            cell: row => <>
                <img className="image" src={row.profile_pic} alt={row.profile_pic} />
            </>,
            sortable: false,
        },
        {
            name: "Posts",
            selector: "total_posts",
            cell: row => row.total_posts,
            sortable: true
        },
        {
            name: "Roles",
            selector: "roles",
            cell: row => <div>
                <div>{row.designation}</div>
                {row.roles?.length > 0 && (
                    <div>
                        ({row.roles.map(role => role.role_name).filter(Boolean).join(", ")})
                    </div>
                )}
                {/* {row.roles?.map((role, index) => (
                    <div key={index}>
                        {role?.role_name}
                    </div>
                ))} */}
            </div>,
            sortable: true
        },
        {
            name: "Pro",
            selector: "is_pro",
            cell: row => `${row.is_pro === true ? "YES" : "NO"} `,
            sortable: true,
        },
        {
            name: "ACTIONS",
            width: "150px",
            selector: row => {
                const HandleEdit = async () => {
                    window.open(`/dashboard/users/${row.id}/edit`, '_blank');
                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`/accounts/user-profiles/${id}/`).then(() => {
                        SetAlertDeletePopupType("error")
                        setDeletepopupType("success")
                        GetData();
                    }).catch((error) => {
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                        console.log(error)
                    })
                }
                return (
                    <>
                        <div className="user-Actions">
                            <div className="d-flex justify-content-between align-items-center gap-3 mb-2">
                                <MdOutlineFileCopy onClick={() => copyToClipboard("profile", row)} className="Copy-icon" />
                                <div onClick={HandleEdit} className="view-btn">Edit</div>
                                {/* <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert} /> */}
                            </div>
                            <div className="message-btn">Message</div>
                        </div>
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]
    return (
        <>
            <DashboardLayout>
                <div className="child-sidenav">
                    <div className="childnav-content">
                        <div>
                            <div className="button-pro">
                                <div className="cat-left-button">
                                    {GetAllUsers ?
                                        <>Showing all users: <button type="button" class="btn btn-primary" onClick={() => setGetAllUsers("")}>Show Only 24 Craft Users</button></>
                                        :
                                        <>Showing 24 Craft Users users: <button type="button" class="btn btn-primary" onClick={() => setGetAllUsers("1")}>Show All Users</button></>
                                    }
                                    {Profile_pic ?
                                        <><button type="button" class="btn btn-primary" onClick={() => setProfile_pic(false)}>Show Only Pic</button></>
                                        :
                                        <><button type="button" class="btn btn-primary" onClick={() => setProfile_pic(true)}>Show With Out Pic</button></>
                                    }
                                    {/* {is_pro ?
                                        <><button type="button" class="btn btn-primary" onClick={() => setIs_pro(false)}>Show With Out Pro</button></>
                                        :
                                        <><button type="button" class="btn btn-primary" onClick={() => setIs_pro(true)}>Show Pro</button></>
                                    } */}
                                </div>
                                <div className="cat-button">
                                    <div className="input-group custom-search">
                                        <input type="text" className="form-control form-search" placeholder="search" value={searchValue} onChange={handleInputChange} />
                                        <div class="input-group-append">
                                            <span class="input-group-text" id="basic-addon2" onClick={handleSearchClick}><MdOutlineSearch /></span>
                                        </div>
                                    </div>
                                    {ShowFilters ?
                                        <button type="button" className="btn btn-primary" onClick={() => setShowFilters(false)}><MdFilterListOff /></button>
                                        :
                                        <button type="button" className="btn btn-primary" onClick={() => setShowFilters(true)}><MdFilterList /></button>
                                    }
                                    <div className="btn" onClick={handleLoginShow} >
                                        + Add New
                                    </div>
                                </div>
                            </div>
                            <Modal
                                show={ShowFilters}
                                onHide={() => setShowFilters(false)}
                                aria-labelledby="example-modal-sizes-title-md"
                            >
                                <Modal.Body className="">
                                    <button variant="primary" className="btn btn-default close-btn" onClick={() => setShowFilters(false)}>
                                        X
                                    </button>
                                    <FormParser modelObject={Masters.FilterForm} formSubmit={formSubmit} />
                                </Modal.Body>
                            </Modal>
                            {/* {ShowFilters &&
                                <div id="applyfilters">
                                    <div className="filters-container">
                                        <div className="filters-form">
                                            <FormParser modelObject={Masters.FilterForm} formSubmit={formSubmit} />
                                        </div>
                                    </div>
                                </div>
                            } */}
                            <div className="right-table">
                                <div className="table-responsive marginTop">
                                    <DataTable
                                        columns={columns}
                                        data={Data} pagination paginationTotalRows={totalRows}
                                        paginationPerPage={RowPerPage}
                                        paginationDefaultPage={currentPage}
                                        onChangePage={(page) => setCurrentPage(page)}
                                        paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                        paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                        onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                        onSort={handleSort}
                                        paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                                        progressComponent={<div className="loader-container">
                                            <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                        </div>}
                                        subHeaderComponent={
                                            <input type="text" className="form-control w-50" placeholder="Search" name="search" value={search.search} onChange={HandleSearch} />} />
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                {popupType != "" &&
                    <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation="/dashboard/masters/products" />
                }
                {deletePopupType != "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                }
                <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={loginShow} onHide={handleLoginClose} className="login-popup">
                    <Modal.Header closeButton>
                        <Modal.Title>Add New User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AddUser handleLoginEvent={handleLoginEvent} />
                    </Modal.Body>
                </Modal>
            </DashboardLayout >
        </>
    )
}
export { Users }