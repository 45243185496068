import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig";
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { MarketingNavbarComponent } from "../Components/navbar";

const HCCOrder = () => {
    let today_date = (new Date()).toISOString().split('T')[0];
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [filteredClients, setFilteredClients] = useState([]);
    const [formData, setFormData] = useState({
        order_date: "",
        orderstatus: "",
        plan: ""
    });
    const [orderStatuses, setOrderStatuses] = useState([
        { label: "--select--", value: "" },
        { label: "init", value: "init" },
        { label: "Pending", value: "pending" },
        { label: "Completed", value: "completed" },
        { label: "Success", value: "Success" },
        { label: "Failed", value: "failed" }
    ]);
    const [storeOptions, setStoreOptions] = useState([]);
    const [Data, SetData] = useState([]);
    let navigate = useNavigate();

    const GetData = async () => {
        setLoading(true);
        try {
            const query = new URLSearchParams();

            if (formData.orderstatus) {
                query.append("orderstatus", formData.orderstatus);
            }
            if (formData.plan) {
                query.append("plan", formData.plan);
            }
            if (formData.order_date) {
                query.append("order_date", formData.order_date);
            }

            query.append("page", currentPage);
            query.append("page_size", RowPerPage);

            const response = await axiosConfig.get(`/accounts/orders/?${query.toString()}`);
            SetData(response.data.results);
            setTotalRows(response.data.count);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchStore = async () => {
        try {
            const response = await axiosConfig.get(`/masters/plans/?page_size=1000`);
            setStoreOptions([{ id: '', name: '---Select Plan---' }, ...response.data.results]);
        } catch (error) {
            console.error(error);
        }
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        };
        return date.toLocaleString('en-US', options);
    }
    const columns = [
        {
            name: "Id",
            selector: "id",
            cell: row => row.id,
            sortable: true
        },
        {
            name: "Profile Name",
            selector: "profile",
            cell: row => <div>{row?.profile?.full_name && row?.profile?.full_name}<br />{row?.profile?.hcc_id && row?.profile?.hcc_id}</div>,
            // cell: row => row.profile?.hcc_id,
            sortable: true
        },
        {
            name: "Plan",
            selector: "plan",
            cell: row => row?.plan?.name,
            sortable: true
        },
        {
            name: "Amount",
            selector: "total_amount",
            cell: row => row?.total_amount,
            sortable: true
        },
        {
            name: "Orderstatus",
            selector: "orderstatus",
            cell: row => row.orderstatus,
            sortable: true
        },
        {
            name: "Order Date",
            selector: "payment_date",
            cell: row => formatDate(row.payment_date),
            sortable: true
        },
        {
            name: "Payment Date",
            selector: "payment_date",
            cell: row => formatDate(row.order_date),
            sortable: true
        }
    ];

    const handleChange = (selectedOption, actionMeta) => {
        console.log(selectedOption, actionMeta)
        const { name } = actionMeta;
        const value = selectedOption ? selectedOption.value : "";
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };
    const handleSubmit = () => {
        GetData();
    };

    const multiSelectStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#c9dddc' : 'white',
            color: state.isSelected ? '#1b7d78' : 'black',
            ':hover': {
                backgroundColor: '#c9dddc',
            },
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#c9dddc',
            color: '#1b7d78',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: '#1b7d78',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: '#1b7d78',
            ':hover': {
                backgroundColor: '#1b7d78',
                color: 'white',
            },
        }),
    };

    useEffect(() => {
        GetData();
        fetchStore();
    }, [currentPage, RowPerPage]);

    return (
        <>
            <DashboardLayout>
                <div className="child-sidenav">
                    <MarketingNavbarComponent />
                    <div className="childnav-content reports-parent">
                        <div className="report-filters">
                            <Select
                                className="flex-1"
                                options={orderStatuses}
                                name="orderstatus"
                                onChange={handleChange}
                                styles={multiSelectStyles}
                                placeholder="Order Status"
                            />
                            <div className="form-group flex-1">
                                <input
                                    className="form-control"
                                    type="date"
                                    name="order_date"
                                    value={formData.order_date}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <Select
                                placeholder="Plan"
                                className="flex-1"
                                name="plan"
                                options={storeOptions.map((res) => (
                                    { "value": res.id, "label": res.name }
                                ))}
                                value={storeOptions.map(res => ({ "value": res.id, "label": res.name })).find(res => res.value === formData.plan)}
                                onChange={handleChange}
                                styles={multiSelectStyles}
                            />
                            <button className="btn btn-primary" style={{ height: '37px' }} onClick={handleSubmit}>Get Reports</button>
                        </div>
                        <div className="table-responsive marginTop">
                            <DataTable
                                columns={columns}
                                data={Data}
                                pagination
                                paginationTotalRows={totalRows}
                                paginationPerPage={RowPerPage}
                                paginationDefaultPage={currentPage}
                                onChangePage={(page) => setCurrentPage(page)}
                                paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                paginationServer
                                fixedHeader
                                subHeader
                                customStyles={tableCustomStyles}
                                progressPending={loading}
                                progressComponent={<div className="loader-container">
                                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                </div>}
                            />
                        </div>
                    </div>
                </div>
                {/* {popupType !== "" &&
                    <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation={`/dashboard/masters/razorpay-payments/${AudiId}/edit`} />
                }
                {deletePopupType !== "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                } */}
            </DashboardLayout>
        </>
    );
};

export { HCCOrder };
