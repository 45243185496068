import React, { useState, useRef } from 'react';
import axiosConfig from '../../Service/axiosConfig';
import AvatarEditor from 'react-avatar-editor';
import Resizer from 'react-image-file-resizer';
import { FaSyncAlt, FaTimes, FaSpinner, FaSearchMinus, FaSearchPlus } from "react-icons/fa";
import { MdPhotoLibrary } from "react-icons/md"
import { PopupModal } from '../PopupModal';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useParams } from 'react-router-dom';
const FabricImageEditor = ({ setCaption, caption, setLoading, loading, setPopupType, popupType, setAddPost, profile_id }) => {
    const [imagesData, setImagesData] = useState([]); // State to store multiple images
    const [editors, setEditors] = useState({}); // Storing editor refs as an object
    const [error, setError] = useState(""); // Error state
    const editorsRef = useRef({});
    let { id } = useParams()
    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        let hasError = false;
        files.forEach((file) => {
            console.log(file.size, "siexe")
            if (file.size < 1024) {
                setError("Image size is too small. Please select an image larger than 1KB.");
                hasError = true;
            }
        });
        if (hasError) {
            return; // Exit if there is an error with any of the files
        }

        files.forEach((file) => {
            const img = new Image();
            img.src = URL.createObjectURL(file);

            img.onload = () => {
                const desiredHeight = 600; // Set desired height for all images
                const aspectRatio = img.width / img.height;
                const width = desiredHeight * aspectRatio;

                setImagesData(prev => [
                    ...prev,
                    {
                        file,
                        imageUrl: URL.createObjectURL(file),
                        width,
                        height: desiredHeight,
                        rotate: 0,
                        scale: 1,
                        caption: ""
                    }
                ]);
                setError(""); // Reset error after successful image load
            };
        });
    };

    const handleRotate = (index) => {
        setImagesData(prev => {
            const newState = [...prev];
            newState[index].rotate = (newState[index].rotate + 90) % 360;
            return newState;
        });
    };

    const handleScaleChange = (index, change) => {
        setImagesData(prev => {
            const newState = [...prev];
            const newScale = newState[index].scale + change;
            newState[index].scale = Math.max(1, Math.min(3, newScale)); // Limit scale between 1 and 3
            return newState;
        });
    };

    const handleRemoveImage = (index) => {
        setImagesData(prev => prev.filter((_, i) => i !== index));
    };

    const handleSave = async () => {
        if (imagesData.length > 0) {
            setLoading(true); // Start loading

            const formData = new FormData();
            formData.append('caption', caption);
            try {
                // Save all images using forEach
                await Promise.all(imagesData.map(async (imageData, index) => {
                    const editorInstance = editorsRef.current[index];
                    if (editorInstance) {
                        const canvas = editorInstance.getImageScaledToCanvas();
                        const blob = await new Promise(resolve => canvas.toBlob(resolve));

                        if (blob) {
                            // Directly call Resizer and use a callback
                            await new Promise((resolve) => {
                                Resizer.imageFileResizer(
                                    blob,
                                    imageData.width,
                                    imageData.height,
                                    'JPEG',
                                    100,
                                    0,
                                    (resizedBlob) => {
                                        if (resizedBlob) {
                                            // Append image and caption without indexing
                                            formData.append('images', resizedBlob);
                                        }
                                        resolve(); // Resolve when done
                                    },
                                    'blob'
                                );
                            });
                        }
                    }
                }));
                console.log(formData.get("images"), "size")
                axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
                const response = await axiosConfig.post(`accounts/model-images/create_with_images/?profile_id=${profile_id ? profile_id : id}`, formData);
                if (response.status === 201) {
                    setPopupType && setPopupType("success");
                    setAddPost(false);
                } else {
                    alert("Unexpected response from the server. Please try again.");
                }
            } catch (error) {
                console.error("Error during image upload:", error);
            } finally {
                setLoading(false);
            }
        }
    };


    const handleCaptionChange = (newCaption) => {
        setCaption(newCaption)
    };

    const sliderSettings = {
        infinite: true,
        speed: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: false,
        dots: true // Add this line to show dots for navigation
    };
    console.log(imagesData)
    return (
        <div className="container">
            <div className="row">
                {error && (
                    <div className="col-sm-12">
                        <div className="alert alert-danger">
                            <FaTimes style={{ fontSize: "20px", marginRight: "10px" }} />
                            {error}
                        </div>
                    </div>
                )}

                {!imagesData.length && (
                    <div className="col-sm-12">
                        <div className="mb-3">
                            <MdPhotoLibrary size={48} />
                            <p>Drag photos and videos here</p>
                            <label className="btn btn-primary">
                                Select photos from computer
                                <input
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    className="form-control"
                                    onChange={handleFileChange}
                                    hidden
                                />
                            </label>
                        </div>
                    </div>
                )}

                {imagesData.length > 0 && (
                    <div className="col-sm-12">
                        <Slider {...sliderSettings}>
                            {imagesData.map((imageData, index) => (
                                <div key={index} className="edit-profile-container">
                                    <AvatarEditor
                                        ref={(ref) => {
                                            if (ref) {
                                                editorsRef.current[index] = ref;
                                            }
                                        }}
                                        image={imageData.imageUrl}
                                        width={imageData.width}
                                        height={imageData.height}
                                        border={50}
                                        color={[255, 255, 255, 0.8]}
                                        scale={imageData.scale}
                                        rotate={imageData.rotate}
                                        className="avatar-editor"
                                    />

                                    {/* Remove Image Icon */}
                                    <FaTimes
                                        onClick={() => handleRemoveImage(index)}
                                        className="remove-image"
                                        title="Remove Image"
                                    />

                                    {/* Zoom Slider */}
                                    {/* <div className="controls-container">
                    <div className="zoom-slider">
                      <label style={{ marginBottom: "0px", marginRight: "10px" }}>Zoom:</label>
                      <input
                        type="range"
                        min="1"
                        max="3"
                        step="0.1"
                        value={imageData.scale}
                        onChange={(e) => handleScaleChange(index, parseFloat(e.target.value))}
                      />
                    </div>
                  </div> */}
                                    <div className="controls-container">
                                        <button
                                            onClick={() => handleScaleChange(index, -0.1)}
                                            className="zoom-control"
                                            style={{
                                                border: 'none',
                                                outline: 'none',
                                                backgroundColor: 'transparent',
                                                cursor: 'pointer'
                                            }}
                                            disabled={imageData.scale <= 1}
                                        >
                                            <FaSearchMinus size={20} />
                                        </button>
                                        <button
                                            onClick={() => handleScaleChange(index, 0.1)}
                                            className="zoom-control"
                                            style={{
                                                border: 'none',
                                                outline: 'none',
                                                backgroundColor: 'transparent',
                                                cursor: 'pointer'
                                            }}
                                            disabled={imageData.scale >= 3}
                                        >
                                            <FaSearchPlus size={20} style={{ fontSize: "20px" }} />
                                        </button>
                                    </div>
                                    <FaSyncAlt
                                        onClick={() => handleRotate(index)}
                                        className="rotate-icon"
                                        title="Rotate"
                                    />
                                </div>
                            ))}
                        </Slider>
                        <textarea
                            className="form-control mb-3"
                            placeholder="Write caption for this image..."
                            value={caption}
                            style={{ marginTop: "25px" }}
                            onChange={(e) => handleCaptionChange(e.target.value)}
                        />
                        <button
                            onClick={handleSave}
                            className="btn btn-primary btn-block"
                            disabled={loading}
                        >
                            {loading ? <FaSpinner className="spin-icon" /> : 'Save'}
                        </button>
                    </div>
                )}
            </div>
            {popupType !== "" && (
                <PopupModal popupType={popupType} setPopupType={setPopupType} Navigation="" />
            )}
        </div>
    );
};

export default FabricImageEditor;