import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal, FormParser } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import { MdOutlineRemoveRedEye } from "react-icons/md"
import DataTable from 'react-data-table-component';
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import { MarketingNavbarComponent } from "../Components/navbar/marketingnav";
import { BiSearch } from "react-icons/bi";
import Select from "react-select";

const RequirementsList = () => {
    const [Data, SetData] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [loading, setLoading] = useState(false)
    const [RowId, SetRowId] = useState()
    const [search, SetSearch] = useState({
        search: ""
    })
    const [formData, setFormData] = useState({
        approval_status: "",
    });
    let navigate = useNavigate();
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
        axiosConfig.get(`/accounts/audition-requirements/?search=${value}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
    }
    const GetData = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(`/accounts/audition-requirements/?page=${currentPage}&page_size=${RowPerPage}${formData?.approval_status ? `&approval_status=${formData.approval_status}` : ""}`);
            // const response = await axiosConfig.get(`/accounts/audition-requirements/?page=${currentPage}&page_size=${RowPerPage}${formData?.approval_status && "&approval_status={formData.approval_status}}`);
            SetData(response.data.results);
            setLoading(false);
            setTotalRows(response.data.count);
        } catch (error) {
            console.log(error);
        }
    };
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "center"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "center",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    }
    const handleRowClick = (row) => {
        console.log(row)
        navigate(`/dashboard/masters/audition-requirements/${row}/edit`)
    }
    function formatDate(inputDate) {
        if (!inputDate) return '';
        const date = new Date(inputDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true
        },
        {
            name: "Id",
            selector: "id",
            cell: row => <>AD{row?.audition?.id && row?.audition?.id}-RD{row.id}</>,
            sortable: true
        },
        {
            name: "HCCID",
            selector: "id",
            cell: row => <div>{row?.audition?.profile?.first_name && row?.audition?.profile?.first_name}<br />{row?.audition?.profile?.hcc_id && row?.audition?.profile?.hcc_id}</div>,
            sortable: true
        },
        {
            name: "Project Title",
            selector: "project_title",
            cell: row => <div>
                <b>{row?.audition?.banner_name ? row?.audition?.banner_name : ""}</b><br />{row?.audition?.project_title ? row?.audition?.project_title : ""}
            </div>,
            sortable: true
        },
        {
            name: "Applications",
            selector: "project_title",
            cell: row => row?.applications,
            sortable: true
        },
        {
            name: "Character",
            selector: "character",
            cell: row => row?.character ? row?.character : "",
            sortable: true
        },
        {
            name: "Age",
            selector: "from_age",
            cell: row => <>
                {row.from_age}-{row.to_age}({row.gender})
            </>,
            sortable: true
        },
        {
            name: "Status",
            selector: "requirement_status",
            cell: row => <>{row?.approval_status}/{row?.requirement_status}</>,
            sortable: true
        },
        {
            name: "ACTIONS",
            selector: row => {
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`/accounts/audition-requirements/${id}/`).then(() => {
                        SetAlertDeletePopupType("error")
                        setDeletepopupType("success")
                        GetData();
                    }).catch((error) => {
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                        console.log(error)
                    })
                }
                return (
                    <>
                        <div className="row" >
                            <div className="col-4">
                                <MdOutlineRemoveRedEye className="dashboard-tabe-data-actions" onClick={() => handleRowClick(row.id)} />
                            </div>
                            {/* <div className="col-4">
                                <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                            </div> */}
                        </div>

                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]
    const [orderStatuses, setOrderStatuses] = useState([
        { label: "--select--", value: "" },
        { label: "requested", value: "requested" },
        { label: "approved", value: "approved" },
        { label: "rejected", value: "rejected" },
    ]);
    const handleChange = (selectedOption, actionMeta) => {
        console.log(selectedOption, actionMeta)
        const { name } = actionMeta;
        const value = selectedOption ? selectedOption.value : "";
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };
    useEffect(() => {
        GetData();
    }, [currentPage, RowPerPage, formData]);
    return (
        <>
            <DashboardLayout>
                <div className="child-sidenav">
                    <MarketingNavbarComponent />
                    <div className="childnav-content">
                        <div className="report-filters">
                            <Select
                                className="flex-1 w-25"
                                options={orderStatuses}
                                name="approval_status"
                                onChange={handleChange}
                                placeholder="Approval Status"
                            />
                        </div>
                        <div className="table-responsive marginTop">
                            <DataTable
                                columns={columns}
                                data={Data} pagination paginationTotalRows={totalRows}
                                paginationPerPage={RowPerPage}
                                paginationDefaultPage={currentPage}
                                onChangePage={(page) => setCurrentPage(page)}
                                paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                                progressComponent={<div className="loader-container">
                                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                </div>}
                            />
                        </div>
                    </div>
                </div>
                {deletePopupType != "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                }
            </DashboardLayout >
        </>
    )
}

export { RequirementsList }
