import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { BiMap, BiEditAlt, BiCalendar } from "react-icons/bi";
import axiosConfig from "../Service/axiosConfig";
import { useNavigate, useParams } from 'react-router-dom';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdOutlineArrowBackIos, MdOutlineFirstPage, MdOutlineLastPage, MdOutlinePhone } from 'react-icons/md';
import { GrAlarm } from 'react-icons/gr';
import "./Application.css"
import { DashboardLayout } from '../Components';
import { MarketingNavbarComponent } from '../Components/navbar';
const AuditionApplications = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(20);
    const [data, setData] = useState([]);
    const [tab, setTab] = useState(0);
    const [filterData, setFilterData] = useState({ gender: '', role: '', search: '', application_status: "" });
    const [editRow, setEditRow] = useState(false);
    const [editFields, setEditFields] = useState({});
    const [totalPages, settotalPages] = useState()
    const navigate = useNavigate();
    const handleNavigate = (link, type) => {
        if (type === "profile") {
            window.open(`https://hyderabadcastingclub.com/profile/${link}`, '_blank');
        } else if (type === "back") {
            navigate(-1);
        } else if (type === "messages") {
            navigate(`/messages`);

        } else {
            navigate(`/auditions`);
        }
    };
    const calculateAge = (dob) => {
        const birthDate = new Date(dob);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };
    const formatDateTime = (timestamp) => {
        const dateObj = new Date(timestamp);
        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const year = dateObj.getFullYear();
        const formattedDate = `${day}/${month}/${year}`;
        let hours = dateObj.getHours();
        const minutes = String(dateObj.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        const formattedTime = `${hours}:${minutes} ${ampm}`;
        return { date: formattedDate, time: formattedTime };
    }
    const HandleShow = (Content) => {
        setEditFields(Content)
        setEditRow(true)
    }
    const Handleclose = () => {
        setEditRow(false)
        setEditFields("")
    }
    const tabStatuses = ["", "applied", "selected", "shortlisted", "rejected"];
    const handleTabChange = (index) => {
        setTab(index);
        setCurrentPage(1);
        setData([]);
        setFilterData(prevData => ({
            ...prevData,
            application_status: tabStatuses[index],
        }));
        fetchAuditionList(1, true, tabStatuses[index]);
    };

    const fetchAuditionList = async (page = currentPage, status) => {
        setLoading(true);
        console.log(filterData)
        try {
            const response = await axiosConfig.get(`accounts/audition-applications/`, {
                params: {
                    page,
                    page_size: rowPerPage,
                    audition_requirement: id,
                    gender: filterData.gender,
                    role: filterData.role,
                    search: filterData.search,
                    application_status: status,
                },
            });
            setData(response.data.results);
            setTotalRows(response.data.count);
            settotalPages(Math.ceil(response.data.count / rowPerPage));
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchAuditionList(currentPage, filterData.application_status);
    }, [rowPerPage, totalPages, currentPage, filterData]);
    const orderStatuses = [
        { label: "--select--", value: "" },
        { label: "Applied", value: "applied" },
        { label: "selected", value: "selected" },
        { label: "Shortlisted", value: "shortlisted" },
        { label: "Rejected", value: "rejected" },
    ];
    const handleFieldChange = (e, field) => {
        setEditFields(prevState => ({
            ...prevState,
            [field]: e.target.value
        }));
    };
    const Handlestatussave = async (row) => {
        const ordertopost = {
            application_status: editFields.application_status,
            message: editFields.message,
            audition_requirement: editFields.audition_requirement
        }
        console.log(ordertopost, editFields)
        try {
            await axiosConfig.put(`/accounts/audition-applications/${editFields.id}/`, ordertopost);
            window.location.reload();
            Handleclose()
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };
    const handleRowsPerPageChange = (event) => {
        setRowPerPage(parseInt(event.target.value));
        setCurrentPage(1);
    };
    return (
        <>
            <DashboardLayout>
                <div className="child-sidenav">
                    <MarketingNavbarComponent />
                    <div className="childnav-content">
                        <div className="content-area">
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className="back-buttons">
                                    <MdOutlineArrowBackIos size={20} style={{ color: "#555555", marginRight: "35px" }} onClick={() => handleNavigate("", "back")} />
                                    Received Applications ({totalRows})
                                </div>
                                {/* <div className='d-flex'>
                            <div className="application-search">
                                <input type="text" placeholder="Search Here" />
                                <BiSearch className="option-icon" />
                            </div>
                        </div> */}
                            </div>
                            <div className='application-container'>
                                <div className='Application-tabs'>
                                    {["All", "Applied", "Selected", "Shortlisted", "Rejected"].map((tabName, index) => (
                                        <div key={index}
                                            className={tab === index ? "chat-type" : "chat-deselect"}
                                            onClick={() => handleTabChange(index)}>
                                            {tabName}
                                        </div>
                                    ))}
                                </div>
                                <div className='application-list'>
                                    {loading && <div className="loader-container">
                                        <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." />
                                    </div>}
                                    {data.map((Data, index) => {
                                        const { date, time } = formatDateTime(Data.created_at);
                                        return (
                                            <div className='application-content' key={index}>
                                                <div className='application-details'>
                                                    {console.log(Data)}
                                                    <div className='flex-between-align me-3'>{(currentPage - 1) * rowPerPage + index + 1}</div>
                                                    <div className="applicant-info" onClick={() => handleNavigate(Data?.profile?.hcc_id, "profile")}>
                                                        <img src={Data.profile?.profile_pic || require('../Components/Login/images/male.jpg')}
                                                            className='applicant-image' width="82px" alt={Data.profile?.first_name} />
                                                        <div className="applicant-details">
                                                            <h4>{Data.profile?.hcc_id}</h4>
                                                            <p>{Data.profile?.first_name} ({Data.profile?.gender === "female" ? "F" : "M"})</p>
                                                            <p className='height'>{calculateAge(Data.profile?.dob)} yrs - {Data.profile.height}</p>
                                                        </div>
                                                    </div>
                                                    <div className='application-loc'>
                                                        <div className="applicant-meta">
                                                            <p><MdOutlinePhone size={16} /> {Data.profile?.mobile_number}</p>
                                                            <p><BiMap size={16} /> {Data.profile?.present_city}</p>
                                                        </div>
                                                        <div className="applicant-meta date-end">
                                                            <p><BiCalendar size={16} /> {date}</p>
                                                            <p><GrAlarm size={16} /> {time}</p>
                                                        </div>
                                                    </div>
                                                    <div className="applicant-actions">
                                                        <p className="statuses">{Data.application_status}</p>
                                                        <div className='action-view'>
                                                            <div className='d-flex justify-content-between align-items-center gap-3'>
                                                                <div className="view-btn" onClick={() => handleNavigate(Data?.profile?.hcc_id, "profile")}>View</div>
                                                                <BiEditAlt size={28} color='#212529' onClick={() => HandleShow(Data)} />
                                                            </div>
                                                        </div>
                                                        {/* <div className="message-btn" onClick={() => handleNavigate(Data?.profile?.hcc_id, "messages")}>
                                                    <img src={require("./images/messages.png")} width={20} />Message
                                                </div> */}
                                                    </div>
                                                </div>
                                                <div className='Application-feedback'>
                                                    <p>Feedback : </p>
                                                    <div className='feedback-content'>{Data.message && Data.message}</div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div>
                                    <div className='d-flex justify-content-end gap-2'>
                                        <span>Rows per page:</span>
                                        <div>
                                            <select aria-label="Rows per page:" style={{ background: "none" }} value={rowPerPage}
                                                onChange={handleRowsPerPageChange}>
                                                <option value="10">10</option>
                                                <option value="20" selected="">20</option>
                                                <option value="30">30</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value={totalRows}>{totalRows}</option>
                                            </select>
                                            <span>
                                                {`${(currentPage - 1) * rowPerPage + 1}-${Math.min(currentPage * rowPerPage, totalRows)} of ${totalRows}`}
                                            </span>
                                        </div>
                                        <div>
                                            <span id="pagination-first-page" aria-label="First Page" aria-disabled="true" disabled={currentPage === 1}
                                                onClick={() => {
                                                    setCurrentPage(1);
                                                    fetchAuditionList(1);
                                                }}>
                                                <MdOutlineFirstPage size={25} />
                                            </span>
                                            <span id="pagination-previous-page" aria-label="Previous Page" aria-disabled="true" disabled={currentPage === 1}
                                                onClick={() => {
                                                    const prevPage = Math.max(1, currentPage - 1);
                                                    setCurrentPage(prevPage);
                                                    fetchAuditionList(prevPage);
                                                }}>
                                                <MdKeyboardArrowLeft size={25} />
                                            </span>
                                            <span id="pagination-next-page" aria-label="Next Page" aria-disabled="false" disabled={currentPage === totalPages}
                                                onClick={() => {
                                                    const nextPage = Math.min(totalPages, currentPage + 1);
                                                    setCurrentPage(nextPage);
                                                    fetchAuditionList(nextPage);
                                                }}>
                                                <MdKeyboardArrowRight size={25} />
                                            </span>
                                            <span id="pagination-last-page" aria-label="Last Page" aria-disabled="false" disabled={currentPage === totalPages}
                                                onClick={() => {
                                                    setCurrentPage(totalPages);
                                                    fetchAuditionList(totalPages);
                                                }}>
                                                <MdOutlineLastPage size={25} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DashboardLayout>
            <Modal size='xs' aria-labelledby="example-modal-sizes-title-xs" show={editRow} centered onHide={Handleclose}>
                <Modal.Header closeButton>
                    <Modal.Title>About Applicant </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "40px" }}>
                    <div>
                        <div className='Application-modal'>
                            <div className="applicant-info">
                                <div onClick={() => handleNavigate(editFields?.profile?.hcc_id, "profile")}>
                                    <img src={editFields.profile?.profile_pic ? editFields.profile?.profile_pic : require('../Components/Login/images/male.jpg')} className='applicant-image' width="82px" alt={editFields.profile?.first_name} />
                                </div>
                                <div className="applicant-details">
                                    <h4>{editFields.profile?.hcc_id}</h4>
                                    <p>{editFields.profile?.first_name} ({editFields.profile?.gender === "female" ? "F" : "M"})</p>
                                </div>
                            </div>
                            <div>
                                <select
                                    className='application-select'
                                    value={editFields.application_status}
                                    onChange={(e) => handleFieldChange(e, 'application_status')}
                                >
                                    {orderStatuses.map(status => (
                                        <option key={status.value} value={status.value}>
                                            {status.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Feedback:</label>
                            <textarea className="form-control" style={{ background: "#EDD3FF", color: "#555555" }} placeholder="Feedback about artist" name="message" onChange={(e) => handleFieldChange(e, 'message')} value={editFields.message} rows="5"></textarea>
                        </div>
                        <button className='btn btn-primary btn-block radius' onClick={Handlestatussave}>Submit</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export { AuditionApplications };